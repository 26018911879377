@use "sass:meta" as ---8miszvug6px;.orbit-container {
  min-height: 450px;
  height: initial !important;
}

.orbit-slide {
  .responsive-embed {
    margin-bottom: 0;
  }
}

.orbit-figure {
  margin-bottom: 0 !important;
  line-height: 0;
}

.orbit-image {
  vertical-align: initial !important;
  width: auto;
  height: 450px;

  @include breakpoint(medium down) {
    object-fit: contain;
  }
}

.orbit-caption {
  color: #fff !important;
  font-style: initial !important;
  font-size: $global-font-size !important;

  @include breakpoint(medium down) {
    line-height: 1.3;
  }
}

;@include ---8miszvug6px.load-css("sass-embedded-legacy-load-done:3767");